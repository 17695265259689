.stepperCustomDot {
  inline-size: 20px;
  block-size: 20px;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--mui-palette-primary-lightOpacity);

  &.activeStepperCustomDot {
    border-width: 5px;
    border-color: var(--mui-palette-primary-main);
    background-color: var(--mui-palette-background-paper);
  }
}

.errorColor {
  color: var(--mui-palette-error-main);
}
